window.onscroll = function () {
    scrollFunction()
};

function scrollFunction() {
    if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
        $('.navbar').addClass('scrolled');
        //$('.navbar .navbar-brand').show();
    } else {
        $('.navbar').removeClass('scrolled');
        //$('.navbar .navbar-brand').hide();
    }
}
